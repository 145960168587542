.sessions_container,
.oauth_container,
.username_emails_container,
.account_validation_container,
.password_resets_container,
.feedback_container,
.register_container {
  .main_body {
    .site_login,
    .forgot_password,
    .reset_password,
    .forgot_username,
    .register,
    .account_register,
    .login_description,
    .site_help,
    .register_benefit {
      margin-top: 30px;
      margin-bottom: 30px;
      @media screen and (min-width: $max_mobile_landscape_width) {
        width: 50%;
        float: left;
        border-right: 1px solid $border_color;
        box-sizing: box;
        padding-right: 50px;
        margin-bottom: 0;
      }
    }
    .account_register,
    .login_description,
    .site_help,
    .register_benefit {
      @media screen and (min-width: $max_mobile_landscape_width) {
        float: right;
        padding-left: 50px;
        padding-right: 0;
        border-right: none;
      }
    }

    header {
      .join-member {
        display: none;
      }
    }
  }

  .reset_password {
    header p {
      font-size: em($large_font_size);
    }
  }

  .register {
    .informational_text {
      color: #666;
      margin-top: 0.2em;
    }

    input[type='checkbox'] {
      margin-right: 2px;
    }
  }
}

.site_login,
.login_description,
.forgot_password_confirmation,
.reset_password,
.password_confirmation,
.forgot_password,
.forgot_username,
.that_was_me .sessions_container,
.oauth_container,
.username_emails_container,
.account_validation_container,
.password_resets_container,
.checkout_container,
.checkout_items_container {
  h1 {
    @extend %headings_3;
    margin-bottom: 30px;
  }
}

.site-recaptcha-widget {
  padding-top: 30px;
}

.oauth_container.music {
  .header {
    margin-bottom: 20px;
    font-weight: 500;
  }
  .music-provider {
    font-size: 20px;
    margin-top: 18px;
    margin-bottom: 20px;
  }

  button {
    font-size: initial;
  }
}
