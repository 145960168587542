.main_body {
  @media screen and (min-width: $max_mobile_landscape_width) {
    margin-bottom: 100px;
  }

  form + p,
  footer > p,
  .new_register,
  .new_username_email {
    margin-bottom: 0px;
  }

  .need_help_with_account {
    margin: 1em 0;
  }

  a {
    color: $link_color;

    &:visited {
      color: $link_color;
    }

    &:hover {
      text-decoration: none;
    }
  }
}

html,
body {
  height: 100%;
}

body {
  display: flex;
  flex-direction: column;

  .content_wrapper {
    flex: 1 1 auto;
  }
}
