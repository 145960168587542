$p_bottom_padding: 20px;
$section_bottom_padding: 40px;
$invalid_token_page_font_size: 16;

.password_resets_container .main_body {
  display: flex;
  justify-content: center;
  text-align: center;

  .site-width {
    .activate-password,
    .invalid-token {
      max-width: 400px;
      margin: 0 auto;
    }

    .activate-password {
      line-height: 1em;

      h1 {
        margin-bottom: $p_bottom_padding;
      }

      p {
        margin-bottom: 0;
        line-height: 1.5em;

        &.hi_esp {
          margin-bottom: 1.5em;
        }
      }

      .need_help_with_account {
        margin-bottom: 0;
      }

      form {
        ng-messages {
          line-height: 1.5em;
        }

        button {
          text-transform: none;
          font-weight: normal;
          padding: 0.6em 1.5em;
        }

        fieldset > ol {
          margin-bottom: $section_bottom_padding;

          > li {
            &.informational {
              > * {
                width: auto;
                font-size: $font_size + px;
                height: auto;
                line-height: 1em;
              }

              label {
                font-size: $font-size + px;
                margin: 0 5px 0 0;
              }

              input {
                font-weight: bolder;
              }
            }

            .informational_text {
              margin-bottom: 0;
            }
          }

          input.ng-invalid.ng-touched {
            border: 2px solid $alpha_primary;
          }
        }
      }
    }

    .invalid-token {
      h1,
      .contact_us_assistance {
        margin-bottom: 0;
      }

      p {
        font-size: $invalid_token_page_font_size + px;
        margin: 1.5em 0;
      }
    }

    .alert_text,
    .informational_text {
      line-height: 1em;
      margin-bottom: 0;
    }
  }

  .checkmark {
    width: 60px;
  }
}
