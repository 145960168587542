.password-wrapper {
  position: relative;

  .show-password,
  .hide-password {
    position: absolute;
    top: 11px;
    right: 10px;
    color: $disabled_link_color;
    font-size: $small_font_size + px;
    text-decoration: none;
    cursor: pointer;
  }
}
